/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, List, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from 'styled-components';
import ReferenceDetailDialog from './ReferenceDetailDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  uploadReference,
  fetchReferenceList,
  referenceActions,
  ReferenceItem,
} from '@ink-ai/portal/reducers/reference';
import { AxiosProgressEvent } from 'axios';
import ReferenceFileItem from './components/ReferenceFileItem';

// Styled Components
const DropZone = styled(Box)`
  margin-top: 10px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 10px 10px 20px;
  text-align: center;
  background: #fafafa;
  transition: all 0.3s ease;
  cursor: pointer;

  &.drag-active {
    border-color: #2196f3;
    background: rgba(33, 150, 243, 0.1);
  }

  &:hover {
    border-color: #2196f3;
  }
`;

const FileListContainer = styled(List)`
  margin-top: 4px;
`;

interface FileUploaderProps {
  maxFileSize?: number; // in MB
  acceptedFileTypes?: string[];
}

export const UploadFileComponent: React.FC<FileUploaderProps> = ({
  maxFileSize = 10, // Default 10MB
  acceptedFileTypes = ['*'],
}) => {
  const dispatch = useDispatch<any>();
  const [files, setFiles] = useState<ReferenceItem[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const referenceState = useSelector((state: RootState) => state.reference);

  const validateFile = (file: File): boolean => {
    // Check file size
    if (file.size > maxFileSize * 1024 * 1024) {
      dispatch(
        referenceActions.setReferenceErrorMessage(
          `File size exceeds ${maxFileSize}MB limit`,
        ),
      );
      return false;
    }

    // Check file type if specific types are required
    if (acceptedFileTypes[0] !== '*') {
      if (!acceptedFileTypes.includes(`.${file.name.split('.').pop()}` || '')) {
        dispatch(
          referenceActions.setReferenceErrorMessage(
            'Please select allowed file type',
          ),
        );
        return false;
      }
    }
    return true;
  };

  const processFiles = (newFiles: File[]) => {
    const validFiles = newFiles.filter(validateFile);
    const newFileItems = validFiles.map((file) => ({
      id: '',
      file,
      name: file.name,
      progress: 0,
      uploading: false,
      checked: false,
    }));

    setFiles((prev) => [...prev, ...newFileItems]);

    // Start upload for each file
    newFileItems.forEach((fileItem) => {
      handleUpload(fileItem);
    });
  };

  const handleUpload = async (fileItem: ReferenceItem) => {
    setFiles((prev) =>
      prev.map((f) => (f.id === fileItem.id ? { ...f, uploading: true } : f)),
    );

    try {
      dispatch(
        uploadReference({
          file: fileItem.file,
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            // upload progress
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1),
            );
            console.info('percentCompleted:', percentCompleted);
            if (percentCompleted >= 100) {
              setFiles((prev) =>
                prev.map((f) =>
                  f.id === fileItem.id ? { ...f, uploading: false } : f,
                ),
              );
            } else {
              setFiles((prev) =>
                prev.map((f) =>
                  f.id === fileItem.id
                    ? { ...f, progress: percentCompleted, uploading: true }
                    : f,
                ),
              );
            }
          },
        }),
      ).then(() => {
        setFiles([]);
      });
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  const handleDragEvents = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    handleDragEvents(e);
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    processFiles(droppedFiles);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    processFiles(selectedFiles);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // fetch reference list
  useEffect(() => {
    dispatch(fetchReferenceList());
  }, []);

  return (
    <Box>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={handleFileSelect}
        style={{ display: 'none' }}
        accept={acceptedFileTypes.join(',')}
      />
      <DropZone
        className={isDragging ? 'drag-active' : ''}
        onDragEnter={(e) => {
          handleDragEvents(e);
          setIsDragging(true);
        }}
        onDragLeave={(e) => {
          handleDragEvents(e);
          setIsDragging(false);
        }}
        onDragOver={handleDragEvents}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <CloudUploadIcon sx={{ fontSize: 48, color: '#757575', mb: 1 }} />
        <Typography fontSize="18px" gutterBottom>
          Drag and drop or browse files
        </Typography>
        <Typography variant="body2" color="textSecondary">
          .docx, .xlsx, or .pdf, max {maxFileSize} MB
        </Typography>
      </DropZone>

      {referenceState.loadingList ? (
        <Box sx={{ mt: 2, width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        <FileListContainer>
          {files.map((fileItem) => (
            <ReferenceFileItem key={fileItem.id} fileItem={fileItem} />
          ))}
          {referenceState.fileList.map((fileItem, index) => (
            <ReferenceFileItem
              key={fileItem.id}
              fileItem={fileItem}
              fileIndex={index}
              openPreview={setOpen}
            />
          ))}
        </FileListContainer>
      )}

      <ReferenceDetailDialog
        open={open}
        onClose={handleClose}
        referenceDetail={referenceState.selectedFileDetail}
      />
    </Box>
  );
};
