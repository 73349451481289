/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { Card } from '@mui/material';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface CollapseCardProps<T> {
  className?: string;
  open?: boolean;
  id?: string;
  item?: T;
  children?: ReactNode;
  onSelected?: (item: T) => void;
}

function _CollapseCard<T>({
  id,
  className,
  open,
  item,
  onSelected,
  children,
}: CollapseCardProps<T>) {
  return (
    <Card
      id={id}
      className={classNames({
        [className]: true,
        'collapse-card': true,
        'my-2': true,
        close: !open,
      })}
      onClick={() => {
        if (!open) {
          onSelected && onSelected(item);
        }
      }}
    >
      {children}
    </Card>
  );
}

export const CollapseCard = styled(_CollapseCard)`
  &.collapse-card {
    border-radius: 15px;
    box-shadow:
      2px 2px 8px -2px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .card-content:last-child {
    padding-bottom: 16px;
  }

  &.close {
    cursor: pointer;
  }
`;
