/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { IconButton, Paper } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import styled from 'styled-components';
import classNames from 'classnames';
import { RootState } from '@ink-ai/portal/reducers';
import { useSelector } from 'react-redux';
import { ClickstreamAnalytics } from '@aws/clickstream-web';
import { copyText } from '@ink-ai/portal/common/utils';

export interface Message {
  id: string;
  text: string;
  type: 'human' | 'bot' | 'system';
  isRestRespReceived?: boolean;
}

interface ChatBlockProps {
  messages: Message[];
}

export const ChatBlock: React.FC<ChatBlockProps> = ({ messages }) => {
  return (
    <Paper
      elevation={3}
      style={{
        left: 0,
        right: 0,
        padding: '20px',
        paddingBottom: '40px',
        boxShadow: 'none',
      }}
    >
      <div style={{ flex: 1, overflowY: 'auto' }}>
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              marginTop: index === 0 ? '0px' : '10px', // Adjust marginTop for the first MessageBubble
              textAlign: message.type === 'human' ? 'right' : 'left',
            }}
          >
            <MessageBubble message={message} />
          </div>
        ))}
      </div>
    </Paper>
  );
};

interface MessageBubbleProps {
  message: Message;
  className?: string;
}

const _MessageBubble = ({ message, className }: MessageBubbleProps) => {
  const { loading, currentChatId } = useSelector(
    (state: RootState) => state.chat,
  );
  const handleCopyClick = () => {
    copyText(message.text);
    ClickstreamAnalytics.record({
      name: 'copy_chat_response_click',
    });
  };
  if (message.type === 'human') {
    return (
      <Paper
        elevation={3}
        className={classNames(className, 'bubble-root', 'bubble-human')}
      >
        {message.text}
      </Paper>
    );
  }
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Paper
        elevation={3}
        className={classNames(className, 'bubble-root', 'bubble-no-human')}
      >
        <span
          className={classNames({
            'chat-cursor': loading && message.id === currentChatId,
          })}
        >
          {/* eslint-disable-next-line */}
          {message.text.replace(/\u000b/g, ' ')}
        </span>
      </Paper>
      {(message.type === 'system' || message.type === 'bot') && (
        <IconButton
          size="small"
          onClick={handleCopyClick}
          style={{ marginLeft: '5px' }}
          aria-label="Copy to clipboard"
        >
          <ContentPasteIcon
            sx={{
              color:
                message.type === 'system' ? 'rgba(255, 0, 0, 0)' : 'default',
            }}
          />
        </IconButton>
      )}
    </div>
  );
};

const MessageBubble = styled(_MessageBubble)`
  @keyframes cursor-blink {
    0% {
      opacity: 0;
    }
  }
  .chat-cursor::after {
    content: '';
    width: 5px;
    height: 20px;
    background: #3c3c3c;
    display: inline-block;
    animation: cursor-blink 1s steps(2) infinite;
    margin: 4px 0 0 2px;
    position: absolute;
  }
  &.bubble-root {
    padding: 10px;
    border-radius: 15px;
    display: inline-block;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: left;
    box-shadow: none;
  }
  &.bubble-human {
    background: #0972d3;
    color: #ffffff;
  }
  &.bubble-no-human {
    flex: 1;
    background: #d1d5db;
    color: #000716;
    min-height: 3em; // Prevents from shrinking when the text is empty for acknowledgement messages
  }
`;
