/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';

interface EditableDivProps {
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const StyledDiv = styled(Box)(() => ({
  display: 'inline-block',
  position: 'relative',
  wordBreak: 'break-all',
  overflow: 'hidden',
  color: '#0288d1',
  backgroundColor: 'rgba(2, 136, 209, 0.08)',
  fontWeight: 600,
  borderRadius: '4px',
  marginLeft: '2px',
  '&[data-placeholder]:before': {
    content: 'attr(placeholder)',
    color: 'rgba(2, 136, 209, 0.5)',
    pointerEvents: 'none',
    position: 'absolute',
    left: '0px',
    top: '0px',
    fontWeight: 400,
  },
  '&:focus': {
    outline: 'none',
    cursor: 'text',
  },
}));

const InstructionInlineEditor: React.FC<EditableDivProps> = ({
  placeholder = '',
  value = '',
  onChange,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const hiddenRef = useRef<HTMLSpanElement>(null);

  // Update input width
  const updateWidth = () => {
    if (hiddenRef.current) {
      const padding = 2;
      const border = 2;
      const calcWidth = hiddenRef.current.offsetWidth + padding + border;
      setWidth(calcWidth > 270 ? 270 : calcWidth);
    }
  };

  const handleInput = () => {
    if (divRef.current) {
      const newValue = divRef.current.textContent || '';
      if (newValue.trim() === '') {
        divRef.current.setAttribute('data-placeholder', 'true');
      } else {
        divRef.current.removeAttribute('data-placeholder');
      }
      onChange?.(newValue);
      updateWidth();
    }
  };

  const handleCopy = (e: ClipboardEvent) => {
    e.preventDefault();
    const selection = window.getSelection();
    if (selection) {
      const plainText = selection.toString();
      e.clipboardData?.setData('text/plain', plainText);
    }
  };

  const handlePaste = async (e: ClipboardEvent) => {
    e.preventDefault();
    const plainText = e.clipboardData?.getData('text/plain') || '';
    const selection = window.getSelection();
    if (selection) {
      selection.deleteFromDocument();
      const range = selection.getRangeAt(0);
      const textNode = document.createTextNode(plainText);
      range.insertNode(textNode);

      // Move cursor to end of pasted text
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
      handleInput();
    }
  };

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener('copy', handleCopy);
      div.addEventListener('paste', handlePaste);
    }
    return () => {
      if (div) {
        div.removeEventListener('copy', handleCopy);
        div.removeEventListener('paste', handlePaste);
      }
    };
  }, []);

  useEffect(() => {
    updateWidth();
  }, [value, placeholder]);

  return (
    <>
      <StyledDiv
        ref={divRef}
        contentEditable
        onInput={handleInput}
        placeholder={placeholder}
        data-placeholder={value.trim() === '' ? 'true' : undefined}
        suppressContentEditableWarning
        sx={{ minWidth: `${width}px` }}
      />
      <span
        ref={hiddenRef}
        style={{
          visibility: 'visible',
          whiteSpace: 'pre',
          position: 'absolute',
          left: -99999,
          pointerEvents: 'none',
          fontSize: 'inherit',
          fontFamily: 'inherit',
        }}
        aria-hidden="true"
      >
        {value || placeholder}
      </span>
    </>
  );
};

export default InstructionInlineEditor;
