/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { useEffect } from 'react';
import { getStore } from '../../reducers';
import { grantToken } from '../../reducers/auth';

// auto auto check should refresh token before token expire
export const useAutoRefreshToken = () => {
  let interval: NodeJS.Timeout;
  useEffect(() => {
    interval = setInterval(() => {
      const store = getStore();
      const { auth } = store.getState();
      // auto refresh token 1 minute before expire
      if (
        auth.expiresAt &&
        auth.expiresAt * 1000 <= Date.now() - 1000 * 60 &&
        auth.refreshToken
      ) {
        store.dispatch(
          grantToken({
            grant_type: 'refresh_token',
            refresh_token: auth.refreshToken,
          }),
        );
      }
      return () => interval && clearInterval(interval);
    }, 10000);
  }, []);
};
