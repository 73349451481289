/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import ConnectorFilterComponent from './connector/ConnectorFilterComponent';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import { identity } from 'lodash-es';
import { connectorFilterActions } from '@ink-ai/portal/reducers/connector-filter';
import { useAsyncData } from '@ink-ai/portal/common/hooks/useAsyncData';
import { ConnectorApi } from '@ink-ai/insight-service-sdk';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import DeleteIcon from '@mui/icons-material/Delete';

const ConnectorSelector = () => {
  const connectorFilterState = useSelector(
    (state: RootState) => state.connectorFilter,
  );
  const dispatch = useDispatch();

  const { data, isLoadingData } = useAsyncData(async () => {
    const connectorApi = await getApi(ConnectorApi);
    return await connectorApi.listConnectors();
  }, []);

  useEffect(() => {
    if (data?.data) {
      dispatch(
        connectorFilterActions.setConnectorListData(data.data.connectors),
      );
    }
  }, [data]);

  if (isLoadingData) {
    return (
      <Box sx={{ mt: 2, width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      {connectorFilterState.selectedConnectors.map((item, index) => {
        return (
          <Box key={identity(index)}>
            <ConnectorFilterComponent
              connectorWithFilter={item}
              connectorIndex={index}
              key={identity(index)}
            />
            <Box
              sx={{ mt: 0, display: 'flex', justifyContent: 'space-between' }}
            >
              {connectorFilterState.selectedConnectors.length === index + 1 ? (
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => {
                    dispatch(connectorFilterActions.addConnector(''));
                  }}
                >
                  Connector
                </Button>
              ) : (
                <Box></Box>
              )}
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => {
                  if (connectorFilterState.selectedConnectors.length === 1) {
                    dispatch(connectorFilterActions.resetInstruction(''));
                  } else {
                    dispatch(
                      connectorFilterActions.removeConnector({
                        index,
                      }),
                    );
                  }
                }}
              >
                Remove
              </Button>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ConnectorSelector;
