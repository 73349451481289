/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConnectorFilterDto,
  ConnectorFilterDtoTypeEnum,
  ConnectorFilterOptionDto,
  ImportConnectorResponseDto,
} from '@ink-ai/insight-service-sdk';

export type OperatorsMap = {
  [key in ConnectorFilterDtoTypeEnum]: ConnectorFilterOptionDto[];
};

export interface SelectedFiltersDto {
  field: ConnectorFilterDto;
  operator: ConnectorFilterOptionDto;
  selectedValue: ConnectorFilterOptionDto;
  stringValue: string;
  dateValue: number;
  dateRangeStartTime: number;
  dateRangeEndTime: number;
}

export interface SelectedConnectors {
  connector: ImportConnectorResponseDto | null;
  maxResults: number;
  selectedFilters: SelectedFiltersDto[];
  showAddFilterDialog: boolean;
  newFilterFiled: ConnectorFilterDto | null;
  newFilterOperator: ConnectorFilterOptionDto | null;
  newFilterSelectionValue: ConnectorFilterOptionDto | null;
  newFilterStringValue: string;
  newFilterDateValue: number | null;
  newFilterDateRangeStartValue: number | null;
  newFilterDateRangeEndValue: number | null;
}

const INIT_CONNECTOR_WITH_FILTERS: SelectedConnectors = {
  connector: null,
  maxResults: 10,
  selectedFilters: [],
  showAddFilterDialog: false,
  newFilterFiled: null,
  newFilterOperator: null,
  newFilterSelectionValue: null,
  newFilterStringValue: '',
  newFilterDateValue: null,
  newFilterDateRangeStartValue: null,
  newFilterDateRangeEndValue: null,
};

export type ConnectorFilterState = {
  connectorsList: ImportConnectorResponseDto[];
  selectedConnectors: SelectedConnectors[];
};

const initialState: ConnectorFilterState = {
  connectorsList: [],
  selectedConnectors: [
    {
      ...INIT_CONNECTOR_WITH_FILTERS,
    },
  ],
};

export const connectorFilter: ReturnType<typeof createSlice> = createSlice({
  name: 'connectorFilter',
  initialState,
  reducers: {
    resetInstruction: (state) => {
      state.selectedConnectors = [
        {
          ...INIT_CONNECTOR_WITH_FILTERS,
        },
      ];
    },
    setConnectorListData: (
      state,
      { payload }: PayloadAction<ImportConnectorResponseDto[]>,
    ) => {
      state.connectorsList = payload;
    },
    addConnector: (state) => {
      state.selectedConnectors.push({
        ...INIT_CONNECTOR_WITH_FILTERS,
      });
    },
    removeConnector: (state, { payload }: PayloadAction<{ index: number }>) => {
      const index = payload.index;
      state.selectedConnectors.splice(index, 1);
    },
    changeCurrentConnector: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        connector: ImportConnectorResponseDto;
      }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].connector = payload.connector;
    },
    changeMaxResults: (
      state,
      { payload }: PayloadAction<{ index: number; maxResults: number }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].maxResults = payload.maxResults;
    },
    showAddFilterDialog: (
      state,
      { payload }: PayloadAction<{ index: number; show: boolean }>,
    ) => {
      state.selectedConnectors[payload.index].showAddFilterDialog =
        payload.show;
    },
    changeNewFilterField: (
      state,
      { payload }: PayloadAction<{ index: number; filter: ConnectorFilterDto }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].newFilterFiled = payload.filter;
    },
    changeNewFilterOperator: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        operator: ConnectorFilterOptionDto;
      }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].newFilterOperator = payload.operator;
    },
    changeNewFilterSelectionValue: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        value: ConnectorFilterOptionDto;
      }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].newFilterSelectionValue = payload.value;
    },
    changeNewFilterStringValue: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        stringValue: string;
      }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].newFilterStringValue =
        payload.stringValue;
    },
    changeNewFilterDateValue: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        dateValue: number;
      }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].newFilterDateValue = payload.dateValue;
    },
    changeNewFilterDateRangeStartValue: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        dateValue: number;
      }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].newFilterDateRangeStartValue =
        payload.dateValue;
    },
    changeNewFilterDateRangeEndValue: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        dateValue: number;
      }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].newFilterDateRangeEndValue =
        payload.dateValue;
    },
    addConnectorFilter: (
      state,
      { payload }: PayloadAction<{ index: number }>,
    ) => {
      const index = payload.index;
      state.selectedConnectors[index].selectedFilters.push({
        field: state.selectedConnectors?.[index]?.newFilterFiled,
        operator: state.selectedConnectors?.[index]?.newFilterOperator,
        selectedValue:
          state.selectedConnectors?.[index]?.newFilterSelectionValue,
        stringValue: state.selectedConnectors?.[index]?.newFilterStringValue,
        dateValue: state.selectedConnectors?.[index]?.newFilterDateValue,
        dateRangeStartTime:
          state.selectedConnectors?.[index]?.newFilterDateRangeStartValue,
        dateRangeEndTime:
          state.selectedConnectors?.[index]?.newFilterDateRangeEndValue,
      });
      // reset new filter selection
      state.selectedConnectors[index].newFilterFiled = null;
      state.selectedConnectors[index].newFilterOperator = null;
      state.selectedConnectors[index].newFilterSelectionValue = null;
      state.selectedConnectors[index].newFilterStringValue = '';
      state.selectedConnectors[index].newFilterDateValue = null;
      state.selectedConnectors[index].newFilterDateRangeStartValue = null;
      state.selectedConnectors[index].newFilterDateRangeEndValue = null;
      // close add dialog
      state.selectedConnectors[index].showAddFilterDialog = false;
    },
    removeConnectorFilter: (
      state,
      {
        payload,
      }: PayloadAction<{ connectorIndex: number; filterIndex: number }>,
    ) => {
      state.selectedConnectors[payload.connectorIndex].selectedFilters.splice(
        payload.filterIndex,
        1,
      );
    },
  },
});

export const connectorFilterActions = connectorFilter.actions;
export default connectorFilter.reducer;
