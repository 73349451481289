/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { CollapseCard } from '@ink-ai/portal/common/components/cards/CollapseCard';
import { RootState } from '@ink-ai/portal/reducers';
import { compose } from '@ink-ai/portal/reducers/compose';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { CardContent, Chip, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const INPUT_PLACEHOLDER = `Explore the capabilities of Amazon Writing Assistant to paraphrase, translate,
summarize, alter the tone, generate images. drag and select the
content on the left to begin.`;

export const InputContent = () => {
  const commonState = useSelector((state: RootState) => state.common);
  const composeState = useSelector((state: RootState) => state.compose);
  const dispatch = useDispatch<any>();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!composeState.textEdited && !isEditing) {
      console.log(isEditing);
      dispatch(compose.actions.syncSelectedText(commonState.selectedText));
    }
  }, [commonState.selectedText, composeState.textEdited, isEditing]);

  return (
    <CollapseCard open={true}>
      <CardContent className="card-content">
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          color="primary"
          fontWeight="bold"
          display="flex"
          alignItems="center"
        >
          <EditNoteOutlinedIcon className="mr-1" />
          <span className="flex-1">Input Content</span>
          <IconButton
            color="error"
            onClick={() => dispatch(compose.actions.clearText())}
            className="mr-1"
          >
            <DeleteOutlinedIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => setIsEditing(!isEditing)}>
            {!isEditing && <EditOutlinedIcon />}
            {isEditing && <CheckOutlinedIcon />}
          </IconButton>
        </Typography>
        {!composeState.inputtedText && !isEditing && (
          <Typography color="text.disabled" gutterBottom>
            {INPUT_PLACEHOLDER}
          </Typography>
        )}
        {(composeState.inputtedText || isEditing) && (
          <>
            {!isEditing && (
              <Typography className="overflow-auto max-h-64" gutterBottom>
                {composeState.inputtedText}
              </Typography>
            )}
            {isEditing && (
              <TextareaAutosize
                minRows={3}
                maxRows={6}
                placeholder={INPUT_PLACEHOLDER}
                value={composeState.inputtedText}
                className="w-full border border-solid border-sky-500 rounded-md p-2"
                onChange={(event) => {
                  dispatch(compose.actions.inputText(event.target.value));
                }}
                autoFocus={true}
              />
            )}
            <Chip
              label={`${composeState.inputtedText.length} / 10000`}
              color={
                composeState.inputtedText.length > 10000 ? 'error' : 'primary'
              }
              variant="outlined"
            />
          </>
        )}
      </CardContent>
    </CollapseCard>
  );
};
