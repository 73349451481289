/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  Button,
  Paper,
  Box,
  Typography,
  ClickAwayListener,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  TextField,
  LinearProgress,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import RenderInstructionField from './instruction/RenderInstructionField';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import { useAsyncData } from '@ink-ai/portal/common/hooks/useAsyncData';
import { WritingInstructionApi } from '@ink-ai/insight-service-sdk';
import { composeInstructionActions } from '@ink-ai/portal/reducers/compose-instruction';
import ReplayIcon from '@mui/icons-material/Replay';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    fontStyle: 'italic',
    opacity: 0.7,
    fontSize: '0.9rem',
  },
});

const InstructionInput = () => {
  const composeInstructionState = useSelector(
    (state: RootState) => state.composeInstruction,
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleChipClick = () => {
    if (composeInstructionState.currentTemplate) {
      return;
    }
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const { data, isLoadingData, reloadData } = useAsyncData(async () => {
    const instructionApi = await getApi(WritingInstructionApi);
    return await instructionApi.queryWritingInstruction({
      pagination: {
        take: 9999,
      },
    });
  }, []);
  const instructionTemplates = data?.data?.data || [];

  if (isLoadingData) {
    return (
      <Box sx={{ mt: 2, width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
      >
        <Typography sx={{ marginLeft: 0 }} variant="h6">
          Writing instruction
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box position="relative">
              <Chip
                avatar={<TextSnippetOutlinedIcon />}
                onClick={handleChipClick}
                label="Template"
                variant="outlined"
                onDelete={
                  composeInstructionState?.currentTemplate
                    ? () => {
                        dispatch(
                          composeInstructionActions.setCurrentTemplate(null),
                        );
                      }
                    : undefined
                }
                sx={{
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  color: composeInstructionState?.currentTemplate
                    ? '#0288d1'
                    : '#888',
                  borderColor: composeInstructionState?.currentTemplate
                    ? '#0288d1'
                    : '#ddd',
                  '& .MuiChip-avatar': {
                    color: composeInstructionState?.currentTemplate
                      ? '#0288d1'
                      : '#888',
                  },
                  '& .MuiChip-deleteIcon': {
                    color: composeInstructionState?.currentTemplate
                      ? '#0288d1'
                      : '#888',
                    visibility: composeInstructionState?.currentTemplate
                      ? 'visible'
                      : 'hidden',
                  },
                }}
              />
              {open && (
                <Paper
                  sx={{
                    position: 'absolute',
                    zIndex: 99,
                    right: 0,
                    width: 280,
                  }}
                >
                  {instructionTemplates.length > 0 ? (
                    <List sx={{ padding: '2px 0' }}>
                      {instructionTemplates.map((item) => {
                        return (
                          <ListItem key={item.id} disablePadding>
                            <ListItemButton
                              onClick={() => {
                                dispatch(
                                  composeInstructionActions.setCurrentTemplate(
                                    item,
                                  ),
                                );
                                setOpen(false);
                              }}
                              sx={{ padding: '0 10px' }}
                            >
                              <ListItemText
                                primary={item.title}
                                secondary={item.description}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : (
                    <Box p={2} textAlign="center">
                      <Typography variant="body1" color="textSecondary">
                        No template available.
                      </Typography>
                      <div>
                        <Button
                          size="small"
                          sx={{ mt: 1 }}
                          variant="contained"
                          onClick={() => {
                            window.open('/writing-instruction/new', '_blank');
                          }}
                        >
                          Create template
                        </Button>
                        <IconButton
                          aria-label="reload"
                          onClick={reloadData}
                          sx={{
                            position: 'absolute',
                            marginLeft: '0.5rem',
                            marginTop: '0.125rem',
                          }}
                        >
                          <ReplayIcon />
                        </IconButton>
                      </div>
                    </Box>
                  )}
                </Paper>
              )}
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>
      {composeInstructionState?.currentTemplate ? (
        <Box>
          <RenderInstructionField
            text={
              composeInstructionState?.currentTemplate?.userInstruction ?? ''
            }
          />
        </Box>
      ) : (
        <>
          <StyledTextField
            sx={{ mt: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={composeInstructionState.userInstructionText}
            onChange={(e) => {
              dispatch(
                composeInstructionActions.changeUserInstructionText(
                  e.target.value,
                ),
              );
            }}
            label="Instruction"
            variant="standard"
            placeholder="Please input your instruction here... to use a provisioned template, click the button on the template at top right..."
            fullWidth
            multiline
            rows={3}
          />
          <Box display="inline-flex" mt="4px">
            <Chip
              size="small"
              label={`${composeInstructionState.currentCharacterCount}/1000`}
              variant="outlined"
            />
          </Box>
        </>
      )}
    </Paper>
  );
};

export default InstructionInput;
