/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';

import {
  Box,
  Typography,
  Checkbox,
  ButtonGroup,
  Tooltip,
  LinearProgress,
  ListItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  deleteReference,
  getReference,
  ReferenceItem,
  referenceActions,
} from '@ink-ai/portal/reducers/reference';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDate } from '@ink-ai/portal/common/utils';
const FileItem = styled(ListItem)`
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 5px;
  padding: 8px 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .action-buttons {
    display: none;
  }

  &:hover .action-buttons {
    display: flex;
  }
`;

interface ReferenceFileItemProps {
  fileItem: ReferenceItem;
  fileIndex?: number;
  openPreview?: (open: boolean) => void;
}

const ReferenceFileItem = ({
  fileItem,
  fileIndex,
  openPreview,
}: ReferenceFileItemProps) => {
  const dispatch = useDispatch<any>();
  const referenceState = useSelector((state: RootState) => state.reference);
  const handleDelete = (id: string) => {
    dispatch(deleteReference(id));
  };

  const handleToggle = (index: number) => {
    // Check if the click is on the ListItem and not on the IconButton
    dispatch(referenceActions.toggleReferenceCheck(index));
  };

  const handleView = (uuid: string) => {
    dispatch(getReference(uuid)).then(() => {
      openPreview?.(true);
    });
  };
  return (
    <FileItem key={fileItem.id}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Checkbox
          id={`file-item-${fileItem.id}`}
          checked={fileItem.checked}
          disabled={fileItem.uploading}
          onChange={() => handleToggle(fileIndex)}
        />
        <Box sx={{ flexGrow: 1 }}>
          <label htmlFor={`file-item-${fileItem.id}`}>
            <Typography variant="body1">{fileItem.name}</Typography>
          </label>
          <Box
            height="30px"
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {fileItem.file ? (
              <Typography variant="body2" color="textSecondary">
                {(fileItem.file?.size / 1024 / 1024).toFixed(2)} MB
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                {formatDate(fileItem.createdAt)}
              </Typography>
            )}

            {!fileItem.uploading && (
              <ButtonGroup size="small" className="action-buttons">
                <Tooltip title="Preview">
                  <LoadingButton
                    loading={referenceState.fetchingItem}
                    size="small"
                    onClick={() => handleView(fileItem.id)}
                    disabled={fileItem.uploading}
                  >
                    <VisibilityIcon />
                  </LoadingButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <LoadingButton
                    loading={referenceState.deleting}
                    size="small"
                    onClick={() => handleDelete(fileItem.id)}
                    disabled={fileItem.uploading}
                  >
                    <DeleteIcon />
                  </LoadingButton>
                </Tooltip>
              </ButtonGroup>
            )}
          </Box>
          {fileItem.uploading && (
            <LinearProgress
              variant="determinate"
              value={fileItem.progress}
              sx={{ mt: 1 }}
            />
          )}
        </Box>
      </Box>
    </FileItem>
  );
};

export default ReferenceFileItem;
