/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { GlossaryCollectionTable } from './GlossaryCollectionTable';
import { app } from '@ink-ai/portal/reducers/app';
import { useDispatch } from 'react-redux';
import { GlossaryCollectionDto } from '@ink-ai/insight-service-sdk';
import { glossaryActions } from '@ink-ai/portal/reducers/glossary';
import { isOffice } from '@ink-ai/portal/common/utils';

interface GlossaryDialogProps {
  open: boolean;
  onClose: () => void;
  onApply: (selectedGlossaries: string[]) => void;
}

const glossaryItemLimit = 2000;

export const GlossaryDialog: React.FC<GlossaryDialogProps> = ({
  open,
  onClose,
  onApply,
}) => {
  const dispatch = useDispatch<any>();
  const [selectedGlossaries, setSelectedGlossaries] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItemCount, setTotalItemCount] = useState(0);

  useEffect(() => {
    if (totalItemCount > glossaryItemLimit) {
      dispatch(
        app.actions.setGlobalMessage({
          message: `The total item count of selected glossaries exceeds ${glossaryItemLimit}.`,
          status: 'error',
        }),
      );
    }
  }, [totalItemCount, dispatch]);

  const handleApply = () => {
    onApply(selectedGlossaries);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleNavigate = (glossary: GlossaryCollectionDto) => {
    dispatch(glossaryActions.setSelectedGlossary(glossary));
    window.open(`/glossary/${glossary.uuid}`, '_blank');
  };

  const isInOffice = isOffice();

  return (
    <Dialog
      fullScreen={isInOffice}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Choose Glossaries</DialogTitle>
      <DialogContent>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          margin="dense"
        />
        <GlossaryCollectionTable
          selectedGlossaries={selectedGlossaries}
          onSelectedGlossariesChange={setSelectedGlossaries}
          searchTerm={searchTerm}
          page={page}
          onPageChange={setPage}
          onNavigate={handleNavigate}
          onTotalItemCountChange={setTotalItemCount}
          allowNonReadySelection={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleApply}
          color="primary"
          variant="contained"
          disabled={totalItemCount > glossaryItemLimit}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
