/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Box } from '@mui/material';
import { Dayjs } from 'dayjs';

interface DateRangePickerProps {
  startDate: Dayjs | null;
  changeStartDate: (newValue: Dayjs | null) => void;
  endDate: Dayjs | null;
  changeEndDate: (newValue: Dayjs | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps,
) => {
  const { startDate, changeStartDate, endDate, changeEndDate } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => changeStartDate(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => changeEndDate(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
