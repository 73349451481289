/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { ReferenceStoreDto } from '@ink-ai/insight-service-sdk';
import { isOffice } from '@ink-ai/portal/common/utils';
import { ReferenceStoreTable } from './ReferenceStoreListTable';
import { referenceStoreActions } from '@ink-ai/portal/reducers/reference-store';

interface ReferenceStoreDialogProps {
  open: boolean;
  onClose: () => void;
  onApply: (selectedReferenceStores: string[]) => void;
}

export const ReferenceStoreDialog: React.FC<ReferenceStoreDialogProps> = ({
  open,
  onClose,
  onApply,
}) => {
  const dispatch = useDispatch<any>();
  const [selectedReferenceStores, setSelectedReferenceStores] = useState<
    string[]
  >([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const handleApply = () => {
    onApply(selectedReferenceStores);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleNavigate = (referenceStore: ReferenceStoreDto) => {
    dispatch(referenceStoreActions.setSelectedReferenceStore(referenceStore));
    window.open(`/reference-store/${referenceStore.uuid}`, '_blank');
  };

  const isInOffice = isOffice();

  return (
    <Dialog
      fullScreen={isInOffice}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Choose Reference Stores</DialogTitle>
      <DialogContent>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          margin="dense"
        />
        <ReferenceStoreTable
          selectedReferenceStores={selectedReferenceStores}
          onSelectedReferenceStoresChange={setSelectedReferenceStores}
          searchTerm={searchTerm}
          page={page}
          onPageChange={setPage}
          onNavigate={handleNavigate}
          allowNonReadySelection={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleApply} color="primary" variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
